@media (min-width: 1025px) {
  .layout-wrapper {
    &.layout-overlay {
      .layout-sidebar {
        left: -350px;
      }

      .layout-topbar {
        left: 0;
      }

      .layout-main,
      .layout-footer {
        margin-left: 0;
      }

      &.layout-overlay-sidebar-active {
        .layout-sidebar {
          left: 0;
        }

        .layout-topbar {
          left: 250px;
        }
      }
    }

    &.layout-static {
      .layout-sidebar {
        left: 0;
      }

      .layout-topbar {
        // left: 250px;
        left: 0;
        .layout-menu-button {
          display: none;
        }
      }

      .layout-main,
      .layout-footer {
        margin-left: 230px;
      }

      &.layout-static-sidebar-inactive {
        .layout-sidebar {
          left: -250px;
        }

        .layout-topbar {
          left: 0;
        }

        .layout-main,
        .layout-footer {
          margin-left: 0;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .layout-wrapper {
    .layout-topbar {
      left: 0;
      .layout-topbar-logo {
        display: none;
      }
    }

    .layout-main,
    .layout-footer {
      margin-left: 0;
    }

    .layout-sidebar {
      left: -350px;
      margin-top: 50px;
    }

    .layout-mask {
      display: none;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 50px;
      left: 0;
      z-index: 998;
      background-color: $maskBgColor;
      @include opacity(0.7);
    }

    &.layout-mobile-sidebar-active {
      .layout-sidebar {
        left: -0;
      }

      .layout-mask {
        display: block;
      }
    }
  }

  .body-overflow-hidden {
    overflow: hidden;
  }
}

@media (min-width: 1400px) {
  .layout-sidebar {
    position: fixed;
    width: 330px;
    height: 100%;
    z-index: 999;
    overflow-y: auto;
    user-select: none;
    margin-top: 100px;
    -moz-user-select: none;
    -webkit-user-select: none;
    transition: left 0.2s;
    // box-shadow: 0 0 6px 0 rgb(0, 0, 0, 16%);
  }

  body {
    .p-tabmenu .p-tabmenu-nav {
      margin-left: 9%;
      margin-right: 3.5%;
    }
  }
}

@media (max-width: 1399px) {
  html {
    .card {
      margin-left: 0%;
      padding-left: 20px;
    }
  }

  .layout-sidebar {
    width: 220px;
  }
}

@media (min-width: 2000px) {
  body {
    .p-tabmenu .p-tabmenu-nav {
      margin-left: 8%;
      margin-right: 3.5%;
    }
  }
}
