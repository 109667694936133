.App-header {
  display: flex;
  align-items: center;
  font-size: calc(10px + 1.5vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container-sem-cadastro {
  width: 100%;
  margin-top: 40px;
}
.container-mensagem-sem-cadastro {
  text-align: center;

}

.produtos {
  width: 50%;
  list-style: none;
  padding-left: 0;
}

.produtos li {
  margin-left: 0.1rem;
  margin-bottom: 1.5rem;
  background-color: #000;
}

.produtos-thumb img {
  display: block;
  width: 100%;
}

.produtos-info {
  padding: 0px;
}

.produtos-info h1 {
  color: #fff;
  font-size: 25px;
  font-weight: 300;
}

.produtos-info p {
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 300;
  margin: 0px;
  color: #fff;
}

.produtos-info-botoes {
  text-align: right;
  padding-top: 1.25rem;
}

.produtos-info-botoes .produtos-info-ver-produto {
  border: 1px solid white;
  background-color: transparent;
  padding: 5px 20px;
  margin-right: 0.5rem;
  color: #fff;
  font-family: "Raleway";
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s;
}
.produtos-info-botoes .produtos-info-ver-produto:hover {
  background-color: white;
  color: #000;
}

.produtos-info-botoes .produtos-info-deletar {
  background-color: #f24949;
  padding: 6px 20px;
  border: none;
  color: #fff;
  font-family: "Raleway";
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s;
}
.produtos-info-botoes .produtos-info-deletar:hover {
  color: #f24949;
  background-color: white;
}
