.layout-footer {
  @include transition(margin-left $transitionDuration);
  background-color: $footerBgColor;
  padding: 1em 2em;
  position: fixed;
  bottom: 0;
  width: 100%;

  .footer-text {
    vertical-align: middle;
  }
}
