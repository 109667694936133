/* Typography */
h1 {
  font-weight: normal;
  margin: 0;
  font-size: 35px;
  font-family: Raleway;
  line-height: 44px;
}

hr {
  margin-top: 8px;
}

h2 {
  font-size: 20px;
  font-weight: normal;
  margin: 0px;
}

.card {
  background-color: #fafafa;
  padding-top: 1.3em;
  padding-left: 4em;
  padding-right: 4em;
  margin-bottom: 16px;
  @include border-radius($borderRadius);
  margin-left: 5.7%;
  &.card-w-title {
    padding-bottom: 2em;
  }

  .header-expo {
    display: flex;
    .h1-container {
      flex: 1;
      h1 {
        font-size: 36px;
        font-family: Raleway;
        font-weight: 400;
        color: #202020ce;
      }
    }

    .filter-container {
      display: flex;
      flex: 2;
      flex-flow: row-reverse;
      align-items: flex-end;
      padding-right: 12px;
      .div-0 {
        flex: 0.18;
        h2 {
          margin-bottom: 0%;
          font-family: Roboto Slab;
          font-size: 18px;
          font-weight: 200;
        }
      }

      .div-1 {
        display: flex;
        flex: 0.2;
        font-family: Raleway;
        input {
          flex: 0.1;
          min-width: 20px;
          cursor: pointer;
        }

        label {
          flex: 0.1;
          font-size: 13.5px;
          margin-top: 1%;
        }
      }

      .div-2 {
        display: flex;
        flex: 0.05;
        font-family: Raleway;
        input {
          flex: 0.1;
          min-width: 20px;
          cursor: pointer;
        }

        label {
          flex: 0.1;
          font-size: 13.5px;
          margin-top: 1%;
        }
      }

      .div-3 {
        display: flex;
        flex: 0.2;
        input {
          flex: 0.1;
          min-width: 20px;
          cursor: pointer;
        }
        label {
          flex: 0.1;
          font-family: Raleway;
          margin-top: 1%;
        }
      }
    }

    .inputtext-container {
      flex: 1.14;
      input {
        padding: 24px 33px;
        border: 2px solid gray;
        border-radius: 5px;
        position: relative;
        font-family: Raleway;
        font-size: 15.4px;
      }
      input::placeholder {
        font-family: Raleway;
        font-size: 15.4px;
      }

      .input-search-icon {
        width: 20px;
        position: absolute;
        right: 85px;
        top: 155px;
        background-color: white;
      }
    }

    .inputtext-container-expositor {
      flex: 1;
      input {
        padding: 24px 33px;
        border: 2px solid gray;
        border-radius: 5px;
        position: relative;
        font-family: Raleway;
        font-size: 15.4px;
      }
      input::placeholder {
        font-family: Raleway;
        font-size: 15.4px;
      }

      .input-search-icon {
        width: 20px;
        position: absolute;
        right: 85px;
        top: 155px;
        background-color: white;
      }
    }

    .h1-container-expositor {
      flex: 2.7;
      h1 {
        font-weight: normal;
        margin: 0;
        font-size: 35px;
        line-height: 44px;
        font-family: Raleway;

        &:first-child {
          margin: 0;
        }
      }
    }
  }

  .hr-header {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  h2 {
    border: none;
    font-size: 24px;
    font-family: Raleway;
    font-weight: 400;
  }

  h3 {
    font-family: Raleway;
    font-size: 24px;
    line-height: 32px;
    font-weight: normal;
    margin: 20px 0;
  }
}

.p-g {
  -ms-flex-wrap: wrap;
}

.scroll-menu {
  width: "100%";
  height: 100%;
  background-color: rgb(0, 0, 0);
}

@media (max-width: 1024px) {
  .filter-container {
    position: absolute;
    visibility: hidden;
  }
}
