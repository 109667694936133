/* Place your extensions here to make migration to a new release easier. */
body {
  a {
    color: #505050;
    text-decoration: none;
  }

  body .pi {
    font-size: 0.9em;
  }

  .p-component {
    font-family: Raleway;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
  }

  .descricao-publicidade {
    padding-left: 0px;
  }

  .layout-sidebar-dark .layout-menu li.active-menuitem > a,
  .layout-sidebar-dark .layout-menu li.active-menuitem .menu-span {
    background-color: #e4e4eb;
    color: #000000;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: flex-start;
    align-items: baseline;
  }

  .layout-menu > li > a span,
  .layout-menu > li .menu-span span {
    font-size: 16px;
    line-height: 24px;
  }

  .layout-menu li a img,
  .layout-menu li .menu-span img {
    margin-left: 20px;
    filter: brightness(0.2);
  }

  .layout-sidebar-dark .layout-menu > li > a,
  .layout-sidebar-dark .layout-menu > li .menu-span {
    border-bottom: 1px solid #464646;
    border-top: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: flex-start;
    align-items: baseline;
  }

  .layout-sidebar-dark .layout-menu li a:hover,
  .layout-sidebar-dark .layout-menu li .menu-span:hover {
    color: #000;
    background-color: #e4e4eb;
  }

  .layout-sidebar-dark .layout-menu li a:hover,
  .layout-sidebar-dark .layout-menu li .menu-span:hover img {
    filter: brightness(1);
  }

  .layout-menu li a span,
  .layout-menu li .menu-span span {
    margin-left: 0.8em;
    vertical-align: middle;
    letter-spacing: 0.9px;
    font-family: Raleway;
    font-weight: normal;
  }

  .p-checkbox {
    display: inline-block;
    vertical-align: middle;

    .p-checkbox-box {
      margin-top: 3px;
      border-radius: 3px;
      width: 15px;
      height: 15px;
      background-color: #ffff;
    }

    .p-checkbox-box.p-highlight {
      border-color: #000000;
      background-color: #ffffff;
      color: #000000;
      padding: 2px;

      .p-checkbox-icon {
        margin-top: -4px;
        overflow: hidden;
        position: relative;
        font-size: 12px;
        padding: 2px 2px 2px 0;
        display: block;
      }
    }
  }

  .p-button {
    min-height: 30px;
    justify-content: center;
    padding: 5px 15px;
    background: #0edeac;
    border-radius: 5px;
    border: none;
    letter-spacing: 0.5px;
    font-family: Raleway;
    color: white;
    font-size: 15.5px;
    line-height: 24px;
    font-weight: normal;
    cursor: pointer;
  }
  .p-button:enabled:hover {
    background: rgb(12, 194, 151);
  }

  .p-datatable {
    .p-datatable-thead {
      tr {
        th:first-child {
          border-top-left-radius: 5px;
        }
        th:last-child {
          border-top-right-radius: 5px;
          border: none;
        }

        th {
          background-color: #000;
          color: white;
          font-family: Raleway;
          font-size: 18px;
          line-height: 26px;
          font-weight: normal;
          letter-spacing: 0.3px;
          text-align: left;
          padding: 8px 10px;
          border: none;
        }
      }
    }

    .p-datatable-tbody {
      tr {
        background-color: rgb(221, 221, 221);

        td:first-child {
          border-left: none;
        }

        td {
          font-family: Roboto Slab;
          color: black;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.3px;
          text-align: left;
          font-weight: 300;
          padding: 8px 10px;
          border: none;
          border-left: 1px solid #505050;
        }
      }
    }
  }
}
body .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: #e1e1e1;
  background-color: #505050;
  color: #ffffff;
}

input,
.p-float-label,
.btn-save,
.full,
textarea,
.p-dropdown-filter-container {
  width: 100% !important;
}

.right {
  text-align: right !important;
}

.m-t-20 {
  margin-top: 20px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.p-b-0 {
  padding-bottom: 0;
}

.t-center {
  text-align: center;
}

.progress {
  width: 50px;
  height: 50px;
}

.p-dropdown {
  width: 195px;
  background-color: lightblue;
  min-width: 0px;
  label {
    border: 1px solid rgba(134, 134, 134, 0.808);
    font-family: Raleway;
    border-radius: 5px;
    font-size: 16px;
    line-height: 24px;
    padding: 0.5em;
  }
}

.react-datepicker {
  display: flex;
}

.p-button {
  min-width: 116px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: #d11010;
  border-radius: 5px;
}

.p-purple {
  background-color: #8c239e !important;
  border: #8c239e !important;
}

.d-block {
  display: block;
}

.hidden {
  display: none;
}

.f12 {
  font-size: 12px;
  span:nth-child(1n + 2)::before {
    content: " | ";
    color: #333;
  }
}

button:disabled {
  opacity: 0.5;
  cursor: default !important;
}

.input-block {
  position: relative;
  align-items: center;
  .input-text {
    font-size: 14px;
    color: #333333;
    background: #ffffff;
    padding: 0.429em;
    border: 1px solid #a6a6a6;
    -moz-transition: border-color 0.2s, box-shadow 0.2s;
    -o-transition: border-color 0.2s, box-shadow 0.2s;
    -webkit-transition: border-color 0.2s, box-shadow 0.2s;
    transition: border-color 0.2s, box-shadow 0.2s;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
  }

  .input-icon {
    position: absolute;
    margin: 15px 12px;
    &.select {
      right: 25px;
    }
  }
  .input-loading {
    position: absolute;
    right: 5px;
    margin-top: 14px;
    &.select {
      right: 25px;
    }
  }
}

.color-success {
  color: #00c496;
}

.color-warn {
  color: #ffba01;
}

.color-danger {
  color: #e91224;
}

.color-reject {
  color: #8c239e;
}

h2 {
  border-bottom: 1px solid #d5d5d5;
  margin-bottom: 12px;
}

h3 {
  font-size: 16px;
  font-weight: normal;
}

.error-input {
  width: 100%;
  font-size: 9px;
  color: #e91224;
  margin-top: 2px;
  margin-bottom: 10px;
}

.quantidade-caracteres {
  color: #007ad9;
  font-size: 12px;
}

.icone-modal-explicativo {
  cursor: pointer;
  margin-left: 5px;
}

.btns-inline {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-medium {
  margin-right: 20px;
}

.layout-topbar {
  border-bottom: 1px solid #505050;
  .layout-topbar-logo {
    width: 230px;
    height: 0;
    padding: 19px;
    text-align: center;
    img {
      height: 52px;
    }
  }
  .layout-topbar-logo2 {
    width: 170px;
    position: absolute;
    padding: 15px 17px 15px;
  }
  .navbar {
    list-style: none;
    margin: 0;
    display: flex;
    align-items: center;
    li {
      display: inline-block;
      position: static;
    }
    .user-menu {
      cursor: pointer;
      min-width: 150px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      font-size: 16px;
      line-height: 24px;
      font-family: Raleway;
      font-weight: normal;
      .box-profile {
        width: 40px;
        height: 40px;
        border: 3px solid #fff;
        border-radius: 50%;
        overflow: hidden;
        margin-left: 10px;
        img {
          width: 100%;
          height: 64%;
          margin-top: 6px;
        }
      }
      .dropdown-menu {
        opacity: 0;
        display: none;
        pointer-events: none;
        position: absolute;
        background-color: #fff;
        color: #000;
        right: 2%;
        top: 52px;
        border: 1px solid #ddd;
        transition: all 0.2s;
        min-width: 280px;
        padding: 0;
        pointer-events: none !important;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
        li {
          width: 100%;
          text-align: center;
        }
      }
    }
    .user-header {
      height: 150px;
      background-color: #e9e9e9;
      color: #000;
    }
    .user-header-profile {
      width: 80px;
      height: 80px;
      border: 3px solid #000;
      border-radius: 50%;
      overflow: hidden;
      margin: 22px auto 0;
      img {
        height: 100%;
      }
    }
    .user-footer {
      padding: 10px;
      .item {
        padding: 10px 0;
        border: 3px solid #000;
        border-radius: 3px;
        margin: 5px 0;
        pointer-events: painted;
        color: #000;
        a {
          color: #0388e5;
        }
        &:hover {
          color: #fff;
          background-color: #000;
        }
      }
      .logout {
        cursor: pointer;
        cursor: pointer;
        font-family: Raleway;
        font-size: 16px;
        line-height: 24px;
        pointer-events: painted !important;
        width: 100%;
        margin: 10px 0 10px 0;
        border: 0;
        color: #333;
        background: unset;
        &:focus {
          outline: 0px auto -webkit-focus-ring-color;
          outline-offset: 0px;
        }
      }
    }
    .user-menu.active {
      .dropdown-menu {
        opacity: 1;
        pointer-events: unset;
        display: unset;
        border: 1px solid #ddd;
        min-width: 280px;
      }
    }
  }
}

.icon-input {
  font-size: 16px;
  transition: all 0.2s;
  i {
    cursor: pointer;
  }
  &:hover {
    color: #c01120;
  }
}

.icon-input-space {
  display: flex;
  align-items: center;
  font-size: 16px;
  transition: all 0.2s;
  i {
    cursor: pointer;
  }
  &:hover {
    color: #c01120;
  }
}
.w-all {
  width: 100%;
}

.add-item {
  color: #009974;
  font-size: 11.5px;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 20px;
  font-family: Raleway;
}

.action-list {
  text-align: center;
  display: flex;
  margin-bottom: 10px;

  button {
    margin-right: 1rem;
  }
}

.space-top {
  margin-top: 50px;
}

.title-page {
  margin-bottom: 20px;
}

.cpf-cnpj {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.valign {
  display: flex;
  align-items: center;
}

.table-row-active {
  color: #fff !important;
  background-color: #007ad9 !important;
}

.table-row-hierarchy-father-disabled {
  pointer-events: none !important;
  opacity: 0.6;
}

.table-row-disabled {
  pointer-events: none !important;
  opacity: 0.5;
}

.guide {
  margin-left: 20px;
  font-size: 14px;
  color: #007ad9;
  cursor: pointer;
  &:hover {
    color: #06c;
  }
}

.dialog {
  width: 50%;
  transition: all 0.2s;
}

.content-title {
  position: relative;
  span {
    position: absolute;
    right: 0;
    &.brand {
      font-size: 14px;
      color: #212121;
      border-radius: 3px;
      padding: 4px 20px;
      &.approved {
        background-color: #b7d8b7;
      }
      &.reject {
        background-color: #f8b7bd;
      }
    }
  }
}

.dashboard {
  .box {
    text-align: center;
    margin: 30px auto 0;
    padding: 0 15px;
    opacity: 0.6;
    .image {
      margin-top: 80px;
      max-width: 600px;
      img {
        width: 100%;
      }
    }
    p {
      font-size: 30px;
      margin-top: 25px;
    }
  }
}

.card-custom {
  height: 68px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
}

.title-name {
  font-size: 12px;
  padding: 0%;
}

.table-company {
  th:nth-child(1) {
    width: 150px;
  }

  th:nth-child(3) {
    width: 200px;
  }
}

.table-iniciative {
  th:nth-child(1),
  th:nth-child(2),
  th:nth-child(4) {
    width: 150px;
  }
  td:nth-child(4) {
    text-align: center;
  }
}

.upload-nota {
  margin: 0;
  padding: 5px;
  color: #6b6b6b;
  background-color: #dcdcdc;
}

.text-link {
  margin-top: 15px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  &:hover {
    color: rgb(216, 216, 216);
  }
}

.link {
  cursor: pointer;
  color: #0388e5;
}

.trashIcon {
  img {
    cursor: pointer;
    padding-top: 10%;
    transition: 0.2s;
  }
  img:hover {
    transform: translateY(15%);
    opacity: 80%;
    transition: 0.2s;
  }
}

@media (max-width: 960px) {
  .dialog {
    width: 80%;
  }
}

@media (max-width: 576px) {
  .dialog {
    width: 95%;
  }
  .layout-topbar {
    .layout-topbar-logo2 {
      width: 112px;
      padding: 20px 17px 20px;
    }
  }
}

.button-save {
  border: 1px solid rgb(14, 222, 172);
  cursor: pointer;
  padding: 10px 20px;
  min-width: 91px;
  background: rgb(14, 222, 172);
  font-family: Raleway;
  color: #ffffff;
  border-radius: 5px;
  font-size: 16px;
  line-height: 24px;
  &:hover {
    color: #fff;
    background: #009c78 !important;
  }
}

.button-cancel {
  border: 1px solid #ff8080;
  cursor: pointer;
  padding: 10px 20px;
  background-color: transparent;
  font-family: Raleway;
  color: #ff8080;
  border-radius: 5px;
  font-size: 16px;
  line-height: 24px;

  &:hover {
    background-color: #f24949;
    color: #fff;
  }
}

.button-voltar {
  border: 1px solid #aaa;
  cursor: pointer;
  padding: 10px 20px;
  background-color: transparent;
  font-family: Raleway;
  color: #aaa;
  border-radius: 5px;
  font-size: 16px;
  line-height: 24px;

  &:hover {
    background-color: #c5c5c5;
    color: #fff;
  }
}

.button-disable {
  border: 1px solid #ffc42e;
  cursor: pointer;
  padding: 10px 20px;
  min-width: 91px;
  background: #ffc42e;
  font-family: Raleway;
  color: #ffffff;
  border-radius: 5px;
  font-size: 16px;
  line-height: 24px;

  &:hover {
    background: #eeab00;
  }
}

.button-delete {
  border: 1px solid #ff8080;
  cursor: pointer;
  padding: 10px 20px;
  background-color: transparent;
  font-family: Raleway;
  color: #ff8080;
  border-radius: 5px;
  font-size: 16px;
  line-height: 24px;

  &:hover {
    background-color: #f24949;
    color: #fff;
  }
}

.button-duplicar {
  border: 1px solid #80a8ff;
  cursor: pointer;
  padding: 10px 20px;
  background: #80a8ff;
  font-family: Raleway;
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  line-height: 24px;

  &:hover {
    background-color: #5d8ef8;
  }
}


.flex-adress-and-phone {
  display: flex;
}

.adress-field {
  flex: 1;
  padding-right: 15px;
  border-right: 1px solid #d5d5d583;
  margin-left: 2px;
}
.phone-field {
  flex: 0.6;
  padding-left: 14px;
  margin-left: 1%;
}

#errorUriMessage {
  color: rgb(235, 3, 3);
  font-size: 15px;
  display: none;
  font-family: Raleway;
  text-align: center;
}

body {
  // container??
  .p-messages .p-messages-wrapper {
    padding: 0;
  }
  .p-messages .p-messages-close .p-messages-close-icon {
    line-height: inherit;
    font-size: 10em;
  }
  .p-messages .p-messages-icon {
    font-size: 0.9em;
  }

  //sucess
  .p-messages.p-messages-success {
    background: none;
  }
  .p-messages.p-messages-success .p-messages-icon {
    color: #fff;
    background: #009974;
    padding: 3px;
    border-radius: 10px;
  }
  .p-messages.p-messages-success {
    border: 0 none;
    color: #8c8c8c;
    .p-messages-close {
      display: none;
      background-color: #009973b7;
      top: 0em;
      color: white;
    }
    .p-messages-summary {
      font-weight: bold;
      margin-left: 0.25em;
      color: #009974;
    }
    .p-messages-detail {
      margin-left: 0.25em;
      color: #009974;
    }
  }

  //warn
  .p-messages.p-messages-warn {
    background: none;

    .p-messages-close {
      display: none;
      background-color: #ffbb00;
      top: 0em;
      color: white;
    }
    .p-messages-summary {
      font-weight: bold;
      margin-left: 0.25em;
      color: #ffbb00;
    }
    .p-messages-detail {
      margin-left: 0.25em;
      color: #ffbb00;
    }
  }
  .p-messages.p-messages-warn .p-messages-icon {
    color: #fff;
    background: #ffbb00;
    padding: 3px;
    border-radius: 10px;
  }
  .p-messages.p-messages-warn {
    border: 0 none;
    color: #8c8c8c;
  }

  //error
  .p-messages.p-messages-error {
    background: none;

    .p-messages-close {
      display: none;
      background-color: #ca1111;
      top: 0em;
      color: white;
    }
    .p-messages-summary {
      font-weight: bold;
      margin-left: 0.25em;
      color: #ca1111;
    }
    .p-messages-detail {
      margin-left: 0.25em;
      color: #ca1111;
    }
  }
  .p-messages.p-messages-error .p-messages-icon {
    color: #fff;
    background: #ca1111;
    padding: 3px;
    border-radius: 10px;
  }
  .p-messages.p-messages-error {
    border: 0 none;
    color: #8c8c8c;
  }
}

body {
  .p-dialog {
    .p-dialog-titlebar {
      border: none;
      background-color: #ffffff;
      color: #333333;
      padding: 1em 1em 0em 1em;
      font-weight: 700;
    }
    .p-dialog-content {
      border: none;
      padding: 1em 1em 1.2em 1em;
    }

    .p-dialog-footer {
      div {
        .p-button-text {
          padding: 0px;
        }

        .p-button {
          background-color: #d51c1c;
          .pi {
            display: none;
          }
        }
        .p-button:hover {
          background: rgba(189, 17, 17, 0.918);
        }

        .p-button.p-button-secondary {
          background: #0edeac;
          color: white;
        }
        .p-button.p-button-secondary:hover {
          background: rgb(12, 194, 151);
        }
      }
    }
  }
}

.language-dashboard {
  padding: 20px 0px;
}

.p-button-dash {
  min-height: 30px;
  justify-content: center;
  padding: 10px 20px;
  background: #000000;
  border-radius: 5px;
  border: none;
  letter-spacing: 0.5px;
  font-family: Raleway;
  color: white;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  cursor: pointer;
}
.p-button-teste {
  border: 1px solid #666565;
  padding: 10px 20px;
  min-height: 30px;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  letter-spacing: 0.5px;
  font-family: Raleway;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}

.required-hash {
  color: red;
}

.quantidade-caracteres {
  font-size: 12px;
  color: rgb(38, 91, 206);
}

body {
  .p-tabmenu {
    .p-tabmenu-nav {
      .p-tabmenuitem.p-highlight {
        .p-menuitem-link {
          background-color: black;
          border: 1px solid black;
        }
      }
    }
  }
}

body .p-datepicker .p-datepicker-buttonbar {
  display: flex;
  justify-content: space-around;
}

@media (max-width: 1080px) {
  .card label {
    font-size: 12px;
  }
}

.dialog-eventos-destaques {
  .p-dialog-titlebar {
    border: none;
    background-color: #ffd873 !important;
    color: #cc960a !important;
    text-align: center !important;
    font-size: 50px;
    padding: 24px !important;
  }
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight a {
  background-color: #000 !important;
  border: 1px solid #2e2e2e;
}

.limpar-filtros-button {
  border: none;
  min-height: 38px;
  background-color: rgba(255, 255, 255, 0);

  span {
    color: hsl(0, 0%, 50%);
    font-size: 14px;
    font-family: "Raleway", "Open Sans";
    cursor: pointer;
  }
}

.text-input-filtragem {
  input {
    background-color: rgba(255, 255, 255, 0.4);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    outline: 0 !important;
    padding-left: 12px;
    width: 300px;

    &::placeholder {
      color: hsl(0, 0%, 50%);
      font-size: 14px;
      font-family: "Raleway", "Open Sans";
    }
  }
}
.container-usuario-filtragem {
  width: 300px;
}

.btn-filtragem {
  background: rgb(14, 222, 172);
  padding: 10px 20px;
  border-radius: 5px;
  font-family: "Raleway", "Open Sans";
  color: white;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  border: none;
}

.container-pesquisa {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

body .p-dialog .p-dialog-footer div .p-button .pi {
  display: block;
}

.css-yk16xz-control, .css-1pahdxg-control {
  height: 42px !important;
}

.css-yk16xz-control {
  border: 1px solid rgba(134, 134, 134, 0.808);
}

.layout-sidebar-dark .layout-menu > li ul {
  background-color: #2b2b2b !important;
}

.input-switch {
  display: flex;
  align-items: center;
  gap: 15px;
}