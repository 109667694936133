#breadcrumb {
  .link {
    color: #0d6efd;
    font-weight: bold;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
  }
}
