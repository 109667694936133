#page-certificados {
  thead .p-selection-column {
    .p-checkbox .p-component {
      display: none !important;
      width: 1px;
    }
  }
  .header-actions {
    display: flex;
    justify-content: space-between;

    .container-pesquisa {
      justify-content: unset !important;

      .text-input-filtragem {
        max-width: 250px !important;
      }

      .titleFilter {
        font-size: 18px;
        color: #a1a1a1;
      }
    }
  }

  .icons {
    display: flex;
    gap: 15px;

    i {
      cursor: pointer;
    }

    i:hover {
      color: #0eb790;
    }
  }

  .p-datatable .p-datatable-tbody > tr.p-highlight {
    background-color: #eef2ff;
  }

  .alert-menssage {
    min-height: 25px;
    margin: 15px 0;
    .p-messages {
      margin: unset !important;
    }
  }

  .status {
    font-size: 14px;
    border-radius: 10px;
    padding: 5px 8px;
  }

  .emitido-enviado {
    color: #155724;
    background-color: #d4edda;
  }

  .enviado {
    color: #0c5460;
    background-color: #d1ecf1;
  }

  .pendente {
    color: #856404;
    background-color: #fff3cd;
  }

  .processando {
    color: #004085;
    background-color: #cce5ff;
  }

  .erro {
    color: #721c24;
    background-color: #f8d7da;
  }

  .nao-solicitado {
    color: #1b1e21;
    background-color: #d6d8d9;
  }
}
